<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v">
        <div class="flex-1 flex-v p025">
          <div class="table flex-1">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="计量表" name="jiliangbiao">
                <div class="top-bar">
                  <el-form ref="form" class="top-form" :model="form">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-form-item>
                          <el-select
                            v-model="equimentId"
                            placeholder="选择设备"
                          >
                            <el-option
                              v-for="item in equiments"
                              :key="item.value"
                              :label="item.name"
                              :value="item.id"
                            />
                            <el-option label="未选择" value="-1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="20">
                        <div class="flex flex-pack-justify">
                          <button class="ann annbg1" @click="getMeterList">
                            查询
                          </button>
                          <button class="ann annbg1 addbtn" @click="onAdd()">
                            添加计量表
                          </button>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <el-table
                  :data="tableData"
                  height="60vh"
                  v-loading="loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                  <el-table-column prop="id" label="ID"> </el-table-column>
                  <el-table-column prop="name" label="名称" width="260">
                  </el-table-column>
                  <el-table-column prop="des" label="概述"> </el-table-column>
                  <el-table-column prop="powerCategoryName" label="能源类型">
                  </el-table-column>
                  <el-table-column
                    prop="huiZongFlag"
                    :formatter="formatFreeShippingInfo"
                    label="能耗参与汇总"
                  >
                  </el-table-column>
                  <el-table-column prop="max" label="计量表上限">
                  </el-table-column>
                  <el-table-column prop="min" label="计量表下限">
                  </el-table-column>
                  <el-table-column
                    prop="parentName"
                    label="上级计量表"
                    width="260"
                  >
                  </el-table-column>
                  <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                      <span
                        class="lk c-main"
                        @click="handleEdit(scope.$index, scope.row)"
                        >修改</span
                      >
                      <span
                        class="lk c-red"
                        @click="handleDelete(scope.$index, scope.row)"
                        >删除</span
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @current-change="handleCurrentChange"
                  v-model:current-page="pageInfo.pageNum"
                  layout="prev, pager, next, jumper,total"
                  :total="pageInfo.total"
                  :page-size="pageInfo.pageSize"
                >
                </el-pagination>
              </el-tab-pane>
              <el-tab-pane label="传感器" name="sensor">
                <div class="top-bar">
                  <el-form ref="form" class="top-form" :model="form">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-form-item>
                          <el-select
                            v-model="equimentId"
                            placeholder="选择设备"
                          >
                            <el-option
                              v-for="item in equiments"
                              :key="item.value"
                              :label="item.name"
                              :value="item.id"
                            />
                            <el-option label="未选择" value="-1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="20">
                        <div class="flex flex-pack-justify">
                          <button class="ann annbg1" @click="getSensorList">
                            查询
                          </button>
                          <button
                            class="ann annbg1 addbtn"
                            @click="onAddSensor()"
                          >
                            添加传感器
                          </button>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <el-table
                  :data="tableDataSensor"
                  height="60vh"
                  v-loading="loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                  <el-table-column prop="id" width="60" label="ID">
                  </el-table-column>
                  <el-table-column prop="name" label="名称"> </el-table-column>
                  <el-table-column prop="des" label="描述"> </el-table-column>
                  <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                      <span
                        class="lk c-main"
                        @click="handleEditSensor(scope.$index, scope.row)"
                        >修改</span
                      >
                      <span
                        class="lk c-red"
                        @click="handleDeleteSensor(scope.$index, scope.row)"
                        >删除</span
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @current-change="handleSensorChange"
                  v-model:current-page="pageInfo.pageNum"
                  layout="prev, pager, next, jumper,total"
                  :total="pageInfo.total"
                  :page-size="pageInfo.pageSize"
                >
                </el-pagination>
              </el-tab-pane>
              <el-tab-pane label="虚拟表" name="virtual">
                <div class="top-bar">
                  <el-form ref="form" class="top-form" :model="form">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-form-item>
                          <el-select
                            v-model="equimentId"
                            placeholder="选择设备"
                          >
                            <el-option
                              v-for="item in equiments"
                              :key="item.value"
                              :label="item.name"
                              :value="item.id"
                            />
                            <el-option label="未选择" value="-1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="20">
                        <div class="flex flex-pack-justify">
                          <button class="ann annbg1" @click="getVirtualMeters">
                            查询
                          </button>
                          <button class="ann annbg3" @click="onAddVirtual()">
                            添加虚拟表
                          </button>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <el-table
                  :data="tableDataVirtual"
                  height="60vh"
                  v-loading="loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                  <el-table-column prop="id" width="60" label="ID">
                  </el-table-column>
                  <el-table-column prop="name" label="名称"> </el-table-column>
                  <el-table-column prop="des" label="描述"> </el-table-column>
                  <el-table-column prop="powerCategoryName" label="能源类型">
                  </el-table-column>
                  <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                      <span
                        class="lk c-main"
                        @click="handleVirtualEdit(scope.$index, scope.row)"
                        >修改</span
                      >
                      <span
                        class="lk c-red"
                        @click="handleVirtualDelete(scope.$index, scope.row)"
                        >删除</span
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @current-change="handleVirtualChange"
                  :current-page="pageInfo.pageNum"
                  layout="prev, pager, next, jumper,total"
                  :total="pageInfo.total"
                >
                </el-pagination>
              </el-tab-pane>
              <el-tab-pane label="离线表" name="outline">
                <div class="top-bar">
                  <el-form ref="form" class="top-form" :model="form">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-form-item>
                          <el-select
                            v-model="equimentId"
                            placeholder="选择设备"
                          >
                            <el-option
                              v-for="item in equiments"
                              :key="item.value"
                              :label="item.name"
                              :value="item.id"
                            />
                            <el-option label="未选择" value="-1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="20">
                        <div class="flex flex-pack-justify">
                          <button
                            class="ann annbg1"
                            @click="getOutlineMeterList"
                          >
                            查询
                          </button>
                          <button
                            class="ann annbg1 addbtn"
                            @click="onAddOutLine()"
                          >
                            添加离线表
                          </button>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <div class="table flex-1">
                  <el-table
                    :data="tableOutLineData"
                    height="60vh"
                    v-loading="loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                  >
                    <el-table-column prop="id" width="60" label="ID">
                    </el-table-column>
                    <el-table-column prop="name" label="名称" width="260">
                    </el-table-column>
                    <el-table-column prop="des" label="描述"> </el-table-column>
                    <el-table-column prop="powerCategoryName" label="能源类型">
                    </el-table-column>
                    <el-table-column prop="max" label="计量表上限">
                    </el-table-column>
                    <el-table-column prop="min" label="计量表下限">
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                      <template slot-scope="scope">
                        <span
                          class="lk c-main"
                          @click="handleOutLineEdit(scope.$index, scope.row)"
                          >修改</span
                        >
                        <span
                          class="lk c-red"
                          @click="handleOutLineDelete(scope.$index, scope.row)"
                          >删除</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <el-pagination
                  @current-change="handleOutLineChange"
                  v-model:current-page="pageInfo.pageNum"
                  layout="prev, pager, next, jumper,total"
                  :total="pageInfo.total"
                  :page-size="pageInfo.pageSize"
                >
                </el-pagination>
              </el-tab-pane>
              <el-tab-pane label="离线表文件" name="outlineFile">
                <div class="top-bar" style="padding-bottom: 25px">
                  <el-form ref="form" class="top-form" :model="form">
                    <el-row :gutter="20">
                      <el-col :span="20">
                        <div class="flex flex-pack-justify">
                          <el-upload
                            class="avatar-uploader"
                            :action="upload.uploadUrl"
                            :headers="upload.headers"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            accept=".xls,.xlsx"
                          >
                            <button class="ann annbg1 addbtn">添加文件</button>
                          </el-upload>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
                <div class="table flex-1">
                  <el-table
                    :data="tableFileData"
                    height="60vh"
                    v-loading="loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                  >
                    <el-table-column prop="id" width="60" label="ID">
                    </el-table-column>
                    <el-table-column prop="name" label="名称" width="260">
                    </el-table-column>
                    <el-table-column prop="des" label="状态"> </el-table-column>
                    <el-table-column prop="powerCategoryName" label="上传时间">
                    </el-table-column>
                    <el-table-column label="操作" width="150">
                      <template slot-scope="scope">
                        <span
                          class="lk c-red"
                          @click="handleFileDelete(scope.$index, scope.row)"
                          >删除</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <el-pagination
                  @current-change="handleOutFileChange"
                  v-model:current-page="pageInfo.pageNum"
                  layout="prev, pager, next, jumper,total"
                  :total="pageInfo.total"
                  :page-size="pageInfo.pageSize"
                >
                </el-pagination>
              </el-tab-pane>
              <el-tab-pane label="绑定数据点" name="bind">
                <div class="gauges-sec flex-1 scrollybg">
                  <div class="choose">
                    <el-select
                      v-model="equimentId"
                      placeholder="选择设备"
                      @change="selectChange"
                    >
                      <el-option
                        v-for="item in equiments"
                        :key="item.value"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </div>
                </div>
                <div class="flex flex-pack-justify">
                  <div class="col">
                    <div class="mod mod1">
                      <h5 class="smtit">计量表</h5>
                      <div class="detail">
                        <el-form ref="form" :model="biaoForm">
                          <el-form-item label="请选择表计类型：">
                            <el-select
                              v-model="biaoForm.type"
                              placeholder="表计类型"
                              @change="selectChange"
                            >
                              <el-option label="计量表" value="1"></el-option>
                              <el-option label="传感器" value="2"></el-option>
                              <el-option label="虚拟表" value="3"></el-option>
                              <el-option label="离线表" value="4"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="请选择表计：">
                            <el-select
                              v-model="biaoForm.meter"
                              placeholder="请选择表计"
                              @change="getBindPoints"
                            >
                              <el-option
                                v-for="item in pointmeters"
                                :key="item.value"
                                :label="item.name"
                                :value="item.id"
                              />
                            </el-select>
                          </el-form-item>
                        </el-form>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="mod mod2">
                      <h5 class="smtit">绑定的数据点</h5>
                      <div class="detail bind">
                        <draggable
                          v-model="pointList"
                          :group="groupBind"
                          animation="300"
                          @add="onAddPoint"
                          @remove="onRemove"
                          class="drag-content"
                        >
                          <div
                            v-for="(point, index) in pointList"
                            class="list"
                            :key="index"
                          >
                            <router-link to="" class="lk bgfull">{{
                              point.pointName
                            }}</router-link>
                          </div>
                        </draggable>
                      </div>
                    </div>
                    <div class="mod mod3">
                      <h5 class="smtit">回收站</h5>
                      <div class="detail">
                        <div class="delete">
                          <draggable
                            :group="groupDelete"
                            animation="300"
                            :sort="true"
                            style="height: 100%; width: 100%"
                          ></draggable>
                          <!-- <img src="../assets/images/set_10.png" alt="" class="icon" /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="mod mod4">
                      <h5 class="smtit">数据点</h5>
                      <div class="detail all">
                        <div class="mb20">
                          <el-input
                            placeholder="请输入内容"
                            v-model="searchInput"
                            class=""
                            @input="handleInput()"
                          >
                            <el-button
                              slot="append"
                              icon="el-icon-search"
                            ></el-button>
                          </el-input>
                        </div>
                        <draggable
                          v-model="fillist"
                          :group="groupAll"
                          animation="300"
                        >
                          <div
                            v-for="(point, key) in fillist"
                            class="list"
                            :key="key"
                          >
                            <router-link to="" class="lk bgfull">{{
                              point.pointName
                            }}</router-link>
                          </div>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加计量表-->
    <el-dialog :visible.sync="addPop" width="40%">
      <div class="pop">
        <div class="toptt">
          <span class="backbtn">添加计量表</span>
          <i class="el-icon-close close" @click="addPop = false"></i>
          <div class="dix"></div>
        </div>
        <div class="detail">
          <el-form ref="form" :model="addForm" label-width="110px">
            <el-row :gutter="10">
              <el-col :span="16">
                <el-form-item label="名称:">
                  <el-input v-model="addForm.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="能耗参与汇总:">
                  <el-switch
                    v-model="addForm.switch"
                    active-color="#9443de"
                    inactive-color="#ddd"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="能源类型:">
                  <el-select
                    v-model="addForm.leixing"
                    placeholder="选择能源类型"
                  >
                    <el-option
                      v-for="item in energyTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="上限:">
                  <el-input v-model="addForm.shangxian"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="下限:">
                  <el-input v-model="addForm.xiaxian"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="上级计量表:">
                  <el-select
                    v-model="addForm.shangji"
                    placeholder="选择上级计量表"
                  >
                    <el-option
                      v-for="item in meters"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="所属设备:">
                  <el-select
                    v-model="addForm.shebei"
                    multiple
                    placeholder="选择所属设备"
                  >
                    <el-option
                      v-for="item in equiments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="描述:">
                  <el-input v-model="addForm.miaoshu"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="btngroup">
              <button class="ann confirm" @click="onSubmit">确定</button>
              <button class="ann back" @click="addPop = false">返回</button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 添加传感器-->
    <el-dialog :visible.sync="addSensorVisable" width="40%">
      <div class="pop">
        <div class="toptt">
          <span class="backbtn">添加传感器</span>
          <i class="el-icon-close close" @click="addSensorVisable = false"></i>
          <div class="dix"></div>
        </div>
        <div class="detail">
          <el-form ref="form" :model="sensorForm" label-width="120px">
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="名称:">
                  <el-input v-model="sensorForm.name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="描述:">
                  <el-input v-model="sensorForm.miaoshu"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="所属设备:">
                  <el-select
                    v-model="sensorForm.shebei"
                    multiple
                    placeholder="选择所属设备"
                    @change="$forceUpdate()"
                  >
                    <el-option
                      v-for="item in equiments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="btngroup">
              <button class="ann confirm" @click="onSubmitSensor">确定</button>
              <button class="ann back" @click="addSensorVisable = false">
                返回
              </button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 添加虚拟表 -->
    <el-dialog :visible.sync="addVirtualPop" width="50%">
      <div class="pop">
        <div class="toptt">
          <span class="backbtn">添加虚拟表</span>
          <i class="el-icon-close close" @click="addVirtualPop = false"></i>
          <div class="dix"></div>
        </div>
        <div class="detail">
          <div class="flex">
            <div class="lbox">
              <el-form ref="form" :model="addVirtualForm" label-width="95px">
                <el-form-item label="名称:">
                  <el-input v-model="addVirtualForm.name"></el-input>
                </el-form-item>
                <el-form-item label="能源分类:">
                  <el-select
                    v-model="addVirtualForm.type"
                    placeholder="请选择能源分类"
                  >
                    <el-option
                      v-for="item in energyTypeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    />
                    <el-option label="未选择" value="-1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="表达式:">
                  <el-input
                    type="textarea"
                    v-model="addVirtualForm.biaodashi"
                    :rows="2"
                  ></el-input>
                </el-form-item>
                <el-form-item label="所属设备:">
                  <el-select
                    v-model="addVirtualForm.shebei"
                    multiple
                    placeholder="选择所属设备"
                  >
                    <el-option
                      v-for="item in equiments"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    />
                    <el-option label="未选择" value="-1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="描述:">
                  <el-input
                    type="textarea"
                    v-model="addVirtualForm.miaoshu"
                    :rows="3"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="rbox flex-1">
              <div class="table">
                <div class="thead">
                  <div class="th w1">变量名</div>
                  <div class="th w2">计量表类型</div>
                  <div class="th w3">计量表名</div>
                  <div class="th w4">操作</div>
                </div>
                <div class="row-xz">
                  <div class="w1">Auto</div>
                  <div class="w2">
                    <el-select
                      v-model="select.type"
                      placeholder="请选择表计类型"
                      @change="getMeters(select.type)"
                    >
                      <el-option label="计量表" value="1"></el-option>
                      <el-option label="虚拟表" value="3"></el-option>
                      <el-option label="离线表" value="4"></el-option>
                    </el-select>
                  </div>
                  <div class="w3">
                    <el-select
                      v-model="select.meterId"
                      placeholder="请选择表计"
                    >
                      <el-option
                        v-for="item in meters"
                        :key="item.value"
                        :label="item.name"
                        :value="item.id"
                      />
                      <el-option label="未选择" value="-1"></el-option>
                    </el-select>
                  </div>
                  <div class="w4">
                    <span class="pointer c-main underline" @click="addMeter"
                      >添加</span
                    >
                  </div>
                </div>
                <div
                  class="row"
                  v-for="(item, index) in addVirtualForm.items"
                  :key="index"
                >
                  <div class="td w1">{{ item.equation }}</div>
                  <div class="td w2">{{ item.typeName }}</div>
                  <div class="td w3">{{ item.gaugesName }}</div>
                  <div class="td w4">
                    <span
                      class="pointer c-red underline"
                      @click="removeMeter(item)"
                      >删除</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btngroup">
            <button class="ann confirm" @click="onSubmitVirtual">确定</button>
            <button class="ann back" @click="addVirtualPop = false">
              返回
            </button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 添加离线表-->
    <el-dialog :visible.sync="addOutlinePop" width="40%">
      <div class="pop">
        <div class="toptt">
          <span class="backbtn">添加离线表</span>
          <i class="el-icon-close close" @click="addOutlinePop = false"></i>
          <div class="dix"></div>
        </div>
        <div class="detail">
          <el-form ref="form" :model="addOutLineForm" label-width="120px">
            <el-row :gutter="10">
              <el-col :span="16">
                <el-form-item label="名称:">
                  <el-input v-model="addOutLineForm.name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="能源类型:">
                  <el-select
                    v-model="addOutLineForm.leixing"
                    placeholder="选择能源类型"
                  >
                    <el-option
                      v-for="item in energyTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="上限:">
                  <el-input v-model="addOutLineForm.shangxian"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="下限:">
                  <el-input v-model="addOutLineForm.xiaxian"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="所属设备:">
                  <el-select
                    v-model="addOutLineForm.shebei"
                    multiple
                    placeholder="选择所属设备"
                  >
                    <el-option
                      v-for="item in equiments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="描述:">
                  <el-input v-model="addOutLineForm.miaoshu"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="btngroup">
              <button class="ann confirm" @click="onOutlineSubmit">确定</button>
              <button class="ann back" @click="addOutlinePop = false">
                返回
              </button>
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { equipList } from "@/api/equip";
import {
  meterList,
  delMeter,
  addMeter,
  editMeter,
  sensorList,
  delSensor,
  addSensor,
  editSensor,
  virtualMeterList,
  delVirtualMeter,
  addVirtualMeter,
  editVirtualMeter,
  outlineMeterList,
  delOutlineMeter,
  addOutlineMeter,
  editOutlineMeter,
  outlineMeterFileList,
  getBindPointList,
  getNotBindPointList,
  addBindPoint,
  delBindPoint,
  delOutlineMeterFile,
} from "@/api/meter";
import {
  getEnergyList,
  getMeterList,
  getEquipList,
  getSensorList,
  getVirtualMeterList,
  getOutlineMeterList,
} from "@/utils/common";
import draggable from "vuedraggable";
export default {
  data() {
    return {
      activeName: "jiliangbiao",
      editId: -1,
      energyTypeList: [],
      form: {
        equimentId: "",
        merterId: "",
        equip: "",
        biaoji: "",
        name: "",
        desc: "",
        level: "",
      },
      biaoForm: {
        type: "",
        equip: "",
      },
      searchInput: "",
      bindPointList: [],
      allPointList: [],
      fillist: [],
      pointList: [],
      groupBind: {
        name: "itxst",
        pull: true, // 拖出
        put: true, // 拖入
      },
      groupAll: {
        name: "itxst",
        pull: true, // 拖出
        put: false, // 拖入
      },
      groupDelete: {
        name: "itxst",
        pull: false, // 拖出
        put: true, // 拖入
      },
      equimentId: "",
      equiments: [],
      pointmeters: [],
      meters: [],
      tableData: [],
      tableDataSensor: [],
      tableDataVirtual: [],
      tableOutLineData: [],
      tablePointData: [],
      tableFileData: [],

      pageInfo: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      loading: false,
      addPop: false,
      addSensorVisable: false,
      addVirtualPop: false,
      addOutlinePop: false,
      //添加传感器
      sensorForm: {
        name: "",
        miaoshu: "",
        shebei: [],
      },
      //计量表
      addForm: {
        name: "",
        switch: true,
        leixing: "",
        shangxian: "",
        xiaxian: "",
        shangji: "",
        shebei: [],
        miaoshu: "",
      },
      //虚拟表
      addVirtualForm: {
        name: "",
        type: "",
        biaodashi: "",
        shebei: [],
        miaoshu: "",
        items: [],
      },
      //离线表
      addOutLineForm: {
        name: "",
        leixing: "",
        shangxian: "",
        xiaxian: "",
        shebei: "",
        miaoshu: "",
      },
      select: {
        type: "",
        meterId: "",
      },
      upload: {
        // 设置上传的请求头部
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        // 上传的地址
        uploadUrl: "/apis/cusGaugesOnlineFile/002-addFile",
      },
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
    draggable,
  },
  created() {
    this.getMeterList();
    this.getEquipList();
    this.getSensorList();
    this.getPointList();
    this.getVirtualMeters();
    this.getOutlineMeterList();
    this.getOutlineMeterFileList();
    this.equiments = getEquipList();
  },
  mounted() {},
  watch: {},
  methods: {
    //取两个数组的差值
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    //获取表计列表
    selectChange() {
      if (this.biaoForm.type == "") {
        return;
      }
      if (this.equimentId == "") {
        this.$message.warning("请选择设备");
        this.biaoForm.type = "";
        return;
      } else {
        if (this.biaoForm.type == "1") {
          this.pointmeters = getMeterList(this.equimentId);
        } else if (this.biaoForm.type == "2") {
          this.pointmeters = getSensorList(this.equimentId);
        } else if (this.biaoForm.type == "3") {
          this.pointmeters = getVirtualMeterList(this.equimentId);
        } else {
          this.pointmeters = getOutlineMeterList(this.equimentId);
        }
      }
    },
    //搜索
    handleInput() {
      this.fillist = [];
      for (let i = 0; i < this.allPointList.length; i++) {
        if (this.allPointList[i].name.includes(this.searchInput)) {
          this.fillist.push(this.allPointList[i]);
        }
      }
    },
    //绑定数据点
    onAddPoint(e) {
      let t = this.getArrDifference(this.pointList, this.bindPointList);
      let param = {
        gaugesId: this.biaoForm.meter,
        pointId: this.getArrDifference(this.pointList, this.bindPointList)[0]
          .id,
        pointName: this.getArrDifference(this.pointList, this.bindPointList)[0]
          .name,
        type: this.biaoForm.type,
      };
      addBindPoint(param).then((res) => {
        if (res.data.code == 0) {
          this.getBindPoints();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //解绑数据点
    onRemove(e) {
      let t = this.getArrDifference(this.pointList, this.bindPointList);
      let param = {
        ids: this.getArrDifference(this.pointList, this.bindPointList)[0].id,
      };
      delBindPoint(param).then((res) => {
        if (res.data.code == 0) {
          this.getBindPoints();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //获取已绑定的数据点列表
    getBindPoints() {
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        deviceId: this.equimentId,
        gaugesId: this.biaoForm.meter,
        type: this.biaoForm.type,
      };
      getBindPointList(param).then((res) => {
        if (res.data.code == 0) {
          this.pointList = res.data.rows;
          this.bindPointList = this.pointList;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
      this.getNotBindPoints();
    },
    //获取指定类型的未绑定数据点列表
    getNotBindPoints() {
      if (this.equimentId == "") {
        this.$message.warning("请选择设备");
        return;
      }
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        gaugesId: this.biaoForm.meter,
        type: this.biaoForm.type,
      };
      getNotBindPointList(param).then((res) => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            res.data.rows[i].pointName = res.data.rows[i].name;
          }
          this.allPointList = res.data.rows;
          this.fillist = this.allPointList;
          this.searchInput = "";
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //信息转换为是否
    formatFreeShippingInfo(row, column) {
      return row.huiZongFlag == "1"
        ? "是"
        : row.huiZongFlag == "0"
        ? "否"
        : row.huiZongFlag;
    },
    //信息转换为是否
    formatFreePointInfo(row, column) {
      return row.isVirtual == "1"
        ? "是"
        : row.isVirtual == "0"
        ? "否"
        : row.isVirtual;
    },
    handleEdit(index, row) {
      this.addForm = {
        name: "",
        switch: true,
        leixing: "",
        shangxian: "",
        xiaxian: "",
        shangji: "",
        shebei: [],
        miaoshu: "",
      };
      this.addForm.name = row.name;
      row.huiZongFlag == "1"
        ? (this.addForm.switch = true)
        : (this.addForm.switch = false);
      this.addForm.leixing = row.powerCategoryId;
      this.addForm.miaoshu = row.des;
      this.addForm.shangji = row.parentId;
      this.addForm.shangxian = row.max;
      this.addForm.xiaxian = row.min;
      if (row.deviceIds != "") {
        this.addForm.shebei = row.deviceIds.split(",").map(Number);
      }
      this.editId = row.id;
      this.addPop = true;
      this.energyTypeList = getEnergyList();
    },
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该计量表, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.deleteMeter(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.getMeterList();
    },
    handleSensorChange(val) {
      this.pageInfo.pageNum = val;
      this.getSensorList();
    },
    handleVirtualChange(val) {
      this.pageInfo.pageNum = val;
      this.getVirtualMeters();
    },
    handleOutLineChange(val) {
      this.pageInfo.pageNum = val;
      this.getOutlineMeterList();
    },
    handleOutFileChange(val) {
      this.pageInfo.pageNum = val;
      this.getOutlineMeterFileList();
    },
    handlePointChange(val) {
      this.pageInfo.pageNum = val;
      this.getPointList();
    },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xls";
      const extension2 = testmsg === "xlsx";
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning",
        });
      }
    },
    handleAvatarSuccess(res, file) {
      this.getOutlineMeterFileList();
    },
    //获取设备列表
    getEquipList() {
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        projectId: Number(sessionStorage.getItem("projectId")),
        projectName: sessionStorage.getItem("projectName"),
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
      };
      equipList(param).then((res) => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            let t = {};
            t.name = res.data.rows[i].name;
            t.id = res.data.rows[i].id;
            this.equiments.push(t);
          }
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //获取传感器列表
    getSensorList() {
      this.loading = true;
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
        deviceId: this.equimentId,
      };
      if (this.equimentId == "-1") {
        delete param.deviceId;
      }
      sensorList(param).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.rows);
          this.pageInfo.total = res.data.total;
          this.tableDataSensor = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },
    //获取虚拟表列表
    getVirtualMeters() {
      this.loading = true;
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
        deviceId: this.equimentId,
      };
      if (this.equimentId == "-1") {
        delete param.deviceId;
      }
      virtualMeterList(param).then((res) => {
        if (res.data.code == 0) {
          this.pageInfo.total = res.data.total;
          this.tableDataVirtual = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },
    //获取计量表列表
    getMeterList() {
      this.loading = true;
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
        deviceId: this.equimentId,
      };
      if (this.equimentId == "-1") {
        delete param.deviceId;
      }
      meterList(param).then((res) => {
        if (res.data.code == 0) {
          this.pageInfo.total = res.data.total;
          this.tableData = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },
    //删除具体计量表
    deleteMeter(id) {
      let param = {
        ids: String(id),
      };
      delMeter(param).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message.warning(res.data.msg);
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        }
      });
    },
    onAddOutLine() {
      this.editId = -1;
      this.addOutLineForm = {
        name: "",
        leixing: "",
        shangxian: "",
        xiaxian: "",
        shebei: "",
        miaoshu: "",
      };
      this.addOutlinePop = true;
      this.energyTypeList = getEnergyList();
    },
    //弹窗
    onAddVirtual() {
      this.editId = -1;
      this.addVirtualForm = {
        name: "",
        type: "",
        biaodashi: "",
        shebei: [],
        miaoshu: "",
        items: [],
      };
      this.select = {
        type: "",
        meterId: "",
      };
      this.addVirtualPop = true;
      this.energyTypeList = getEnergyList();
    },
    //添加弹窗
    onAddSensor() {
      this.editId = -1;
      this.sensorForm = {
        name: "",
        miaoshu: "",
        shebei: [],
      };
      this.addSensorVisable = true;
    },
    //添加设备弹窗
    onAdd() {
      this.editId = -1;
      this.addForm = {
        name: "",
        switch: true,
        leixing: "",
        shangxian: "",
        xiaxian: "",
        shangji: "",
        shebei: [],
        miaoshu: "",
      };
      this.addPop = true;
      this.meters = getMeterList();
      this.energyTypeList = getEnergyList();
    },
    //添加设备弹窗
    onPointAdd() {
      this.editId = -1;
      this.addPointForm = {
        name: "",
        switch: true,
        type: "",
        danwei: "",
        xishu: "",
        shangxian: "",
        xiaxian: "",
        miaoshu: "",
      };
      this.addPointPop = true;
    },
    //添加/修改设备
    onSubmit() {
      if (this.addForm.name == "") {
        this.$message.warning("请填写计量表名");
      } else if (this.addForm.leixing == "") {
        this.$message.warning("请填写能源类型");
      } else {
        let energyName = "";
        let meterName = "";
        for (let i = 0; i < this.energyTypeList.length; i++) {
          if (this.energyTypeList[i].id == this.addForm.leixing) {
            energyName = this.energyTypeList[i].name;
          }
        }
        for (let i = 0; i < this.meters.length; i++) {
          if (this.meters[i].id == this.addForm.shangji) {
            meterName = this.meters[i].name;
          }
        }

        let param = {
          huiZongFlag: Number(this.addForm.switch),
          name: this.addForm.name,
          deviceIds: this.addForm.shebei.join(","),
          des: this.addForm.miaoshu,
          powerCategoryId: this.addForm.leixing,
          powerCategoryName: energyName,
          max: this.addForm.shangxian,
          min: this.addForm.xiaxian,
          parentId: this.addForm.shangji,
          parentName: meterName,
        };
        if (this.editId == -1) {
          addMeter(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addPop = false;
              this.getMeterList();
            }
          });
        } else {
          param.id = this.editId;
          editMeter(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addPop = false;
              this.getMeterList();
            }
          });
        }
      }
    },
    //添加/修改传感器
    onSubmitSensor() {
      if (this.sensorForm.name == "") {
        this.$message.warning("请填写传感器名");
      } else {
        let param = {
          name: this.sensorForm.name,
          des: this.sensorForm.miaoshu,
          deviceIds: this.sensorForm.shebei.join(","),
        };
        if (this.editId == -1) {
          addSensor(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addSensorVisable = false;
              this.getSensorList();
            }
          });
        } else {
          param.id = this.editId;
          editSensor(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addSensorVisable = false;
              this.getSensorList();
            }
          });
        }
      }
    },
    //添加/修改虚拟表
    onSubmitVirtual() {
      if (this.addVirtualForm.name == "") {
        this.$message.warning("请填写虚拟表名");
      } else if (this.addVirtualForm.leixing == "") {
        this.$message.warning("请填写能源类型");
      } else {
        let energyName = "";
        for (let i = 0; i < this.energyTypeList.length; i++) {
          if (this.energyTypeList[i].id == this.addVirtualForm.type) {
            energyName = this.energyTypeList[i].name;
          }
        }
        let param = {
          name: this.addVirtualForm.name,
          item: JSON.stringify(this.addVirtualForm.items),
          equation: this.addVirtualForm.biaodashi,
          deviceIds: this.addVirtualForm.shebei.join(","),
          des: this.addVirtualForm.miaoshu,
          powerCategoryId: this.addVirtualForm.type,
          powerCategoryName: energyName,
        };
        console.log(param);
        if (this.editId == -1) {
          addVirtualMeter(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addVirtualPop = false;
              this.getVirtualMeters();
            }
          });
        } else {
          param.id = this.editId;
          editVirtualMeter(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addVirtualPop = false;
              this.getVirtualMeters();
            }
          });
        }
      }
    },
    handleEditSensor(index, row) {
      console.log(row);
      this.sensorForm.name = row.name;
      this.sensorForm.miaoshu = row.des;
      this.editId = row.id;
      if (row.deviceIds != "") {
        this.sensorForm.shebei = row.deviceIds.split(",").map(Number);
      }
      this.addSensorVisable = true;
    },
    handleDeleteSensor(index, row) {
      this.$confirm("此操作将永久删除该传感器, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableDataSensor.splice(index, 1);
          this.deleteSensor(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //删除具体传感器
    deleteSensor(id) {
      let param = {
        ids: String(id),
      };
      delSensor(param).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message.warning(res.data.msg);
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        }
      });
    },
    handleVirtualEdit(index, row) {
      this.addVirtualForm = {
        name: "",
        type: "",
        biaodashi: "",
        shebei: [],
        miaoshu: "",
        items: [],
      };
      this.select = {
        type: "",
        meterId: "",
      };
      this.addVirtualForm.name = row.name;
      this.addVirtualForm.type = row.powerCategoryId;
      this.addVirtualForm.miaoshu = row.des;
      this.addVirtualForm.biaodashi = row.equation;
      if (row.deviceIds != "") {
        this.addVirtualForm.shebei = row.deviceIds.split(",").map(Number);
      }
      this.addVirtualForm.items = JSON.parse(row.item);
      this.editId = row.id;
      this.addVirtualPop = true;
      this.energyTypeList = getEnergyList();
    },
    handleVirtualDelete(index, row) {
      this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableDataVirtual.splice(index, 1);
          this.deleteVirtualMeter(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //删除具体虚拟表
    deleteVirtualMeter(id) {
      let param = {
        ids: String(id),
      };
      delVirtualMeter(param).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message.warning(res.data.msg);
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        }
      });
    },
    //判断数组非空
    shouldRenderTags(tag) {
      console.log(tag);
      return Array.isArray(tag) && tag.every((item) => item !== "");
    },
    //查询可作为公式的表
    getMeters(type) {
      if (type == "1") {
        this.meters = getMeterList();
      } else if (type == "3") {
        this.meters = getVirtualMeterList();
      } else {
        this.meters = getOutlineMeterList();
      }
    },
    getMetersPoint(equimentId) {
      this.form.merterId = "";
      this.meters = getMeterList(equimentId);
    },
    //将表添加进公式
    addMeter() {
      let t = {};
      t.equation = "x" + String(this.addVirtualForm.items.length + 1);
      for (let i = 0; i < this.addVirtualForm.items.length; i++) {
        if (this.addVirtualForm.items[i].equation == t.equation) {
          t.equation = "x" + String(this.addVirtualForm.items.length + 2);
        }
      }
      t.type = this.select.type;
      if (t.type == "1") {
        t.typeName = "计量表";
      } else if (t.type == "3") {
        t.typeName = "虚拟表";
      } else {
        t.typeName = "离线表";
      }
      t.gaugesId = this.select.meterId;
      for (let i = 0; i < this.meters.length; i++) {
        if (this.meters[i].id == t.gaugesId) {
          t.gaugesName = this.meters[i].name;
        }
      }
      this.addVirtualForm.items.push(t);
    },
    //获取离线表列表
    getOutlineMeterList() {
      this.loading = true;
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
        deviceId: this.equimentId,
      };
      if (this.equimentId == "-1") {
        delete param.deviceId;
      }
      outlineMeterList(param).then((res) => {
        if (res.data.code == 0) {
          this.pageInfo.total = res.data.total;
          this.tableOutLineData = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },
    //获取离线表文件列表
    getOutlineMeterFileList() {
      this.loading = true;
      let param = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
      };
      outlineMeterFileList(param).then((res) => {
        if (res.data.code == 0) {
          this.pageInfo.total = res.data.total;
          this.tableFileData = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },
    //删除具体离线表文件
    deleteOutlineMeterFile(id) {
      let param = {
        ids: String(id),
      };
      delOutlineMeterFile(param).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message.warning(res.data.msg);
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        }
      });
    },
    handleOutLineEdit(index, row) {
      this.addOutLineForm = {
        name: "",
        leixing: "",
        shangxian: "",
        xiaxian: "",
        shebei: [],
        miaoshu: "",
      };
      this.addOutLineForm.name = row.name;
      this.addOutLineForm.leixing = row.powerCategoryId;
      this.addOutLineForm.miaoshu = row.des;
      this.addOutLineForm.shangxian = row.max;
      this.addOutLineForm.xiaxian = row.min;
      if (row.deviceIds != "") {
        this.addOutLineForm.shebei = row.deviceIds.split(",").map(Number);
      }
      this.editId = row.id;
      this.addOutlinePop = true;
      this.energyTypeList = getEnergyList();
    },
    handleOutLineDelete(index, row) {
      this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableOutLineData.splice(index, 1);
          this.deleteOutlineMeter(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //删除具体离线表
    deleteOutlineMeter(id) {
      let param = {
        ids: String(id),
      };
      delOutlineMeter(param).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message.warning(res.data.msg);
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        }
      });
    },
    handleFileDelete(index, row) {
      this.$confirm("此操作将永久删除该设备, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableFileData.splice(index, 1);
          this.deleteOutlineMeterFile(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onOutlineSubmit() {
      if (this.addOutLineForm.name == "") {
        this.$message.warning("请填写离线表名");
      } else if (this.addOutLineForm.leixing == "") {
        this.$message.warning("请填写能源类型");
      } else {
        let energyName = "";
        for (let i = 0; i < this.energyTypeList.length; i++) {
          if (this.energyTypeList[i].id == this.addForm.leixing) {
            energyName = this.energyTypeList[i].name;
          }
        }
        let param = {
          name: this.addOutLineForm.name,
          deviceIds: this.addOutLineForm.shebei.join(","),
          des: this.addOutLineForm.miaoshu,
          powerCategoryId: this.addOutLineForm.leixing,
          powerCategoryName: energyName,
          max: this.addOutLineForm.shangxian,
          min: this.addOutLineForm.xiaxian,
        };
        if (this.editId == -1) {
          addOutlineMeter(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addOutlinePop = false;
              this.getOutlineMeterList();
            }
          });
        } else {
          param.id = this.editId;
          editOutlineMeter(param).then((res) => {
            if (res.data.code != 0) {
              this.$message.warning(res.data.msg);
            } else {
              this.addOutlinePop = false;
              this.getOutlineMeterList();
            }
          });
        }
      }
    },
    handleClick(target, action) {
      if (target.name == "sensor") {
        this.equimentId = "";
      } else if (target.name == "virtual") {
        this.equimentId = "";
      } else if ((target.name = "outline")) {
        this.equimentId = "";
      }
    },
    //将表从公式中移除
    removeMeter(item) {
      this.$confirm("确认删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let index = this.addVirtualForm.items.indexOf(item);
          if (index !== -1) {
            this.addVirtualForm.items.splice(index, 1);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style scoped>
.pop .detail {
  padding-right: 20px;
}
::v-deep .el-tabs__item {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
::v-deep .el-tabs__nav-scroll {
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  background: url(../assets/images/icon_04.png) no-repeat left bottom;
  background-size: 196px 20px;
  padding-top: 10px;
}
/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}
::v-deep .el-tabs__active-bar {
  background-color: transparent;
}

.choose {
  width: 260px;
  margin-bottom: 25px;
}

.col {
  width: 30%;
  max-width: 387px;
}

.mod {
  margin-bottom: 25px;
}

.mod .smtit {
  font-size: 18px;
  text-align: center;
  width: 340px;
  line-height: 44px;
  margin: 0 auto;
  height: 44px;
  overflow: hidden;
  padding-top: 2px;
}

.mod .detail {
  padding: 25px 35px;
}

.mod .detail.bind {
  height: 300px;
  overflow-y: auto;
}

.drag-content {
  height: 240px;
}

.mod .detail.all {
  height: 600px;
  overflow-y: auto;
}

.mod1 {
  min-height: 300px;
  background: url(../assets/images/set_01.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 .smtit {
  background: url(../assets/images/set_05.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 >>> .el-select {
  display: block;
}

.mod1 >>> .el-form-item__label {
  float: none;
  text-align: left;
}

.mod2 {
  min-height: 348px;
  background: url(../assets/images/set_02.png) no-repeat center;
  background-size: 100% 100%;
}

.mod2 .smtit {
  background: url(../assets/images/set_06.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 {
  min-height: 378px;
  background: url(../assets/images/set_03.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 .smtit {
  background: url(../assets/images/set_07.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 .delete {
  background: url(../assets/images/set_10.png) no-repeat center;
  background-size: 100% 100%;
  width: 330px;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mod3 .delete .icon {
  width: 180px;
}

.mod4 {
  min-height: 658px;
  background: url(../assets/images/set_04.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 .smtit {
  background: url(../assets/images/set_08.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 >>> .el-input-group {
  background: rgba(26, 29, 50, 0.5);
  border: 1px solid #4a4f64;
  border-radius: 4px;
  overflow: hidden;
}

.mod4 >>> .el-input-group__append {
  background: transparent;
  border: none;
  color: #fff;
}

.mod4 >>> .el-input-group .el-input__inner {
  border: none !important;
  background: none !important;
}

.list .lk {
  display: flex;
  font-size: 16px;
  height: 52px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.list .lk:last-child {
  margin-bottom: 0;
}

.mod2 .list .lk {
  background-image: url(../assets/images/set_13.png);
}

.mod2 .list .lk:hover {
  color: #8e7ff6;
}

.mod4 .list .lk {
  background-image: url(../assets/images/set_12.png);
}

.mod4 .list .lk:hover {
  color: #71aef5;
}
</style>
